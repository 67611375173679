<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row mx-10">
          <div class="d-flex flex-column">
            <h3
              class="font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"
            >
              {{ schoolName ? schoolName : "All Students" }}
            </h3>
          </div>
        </div>
        <Breadcrumbs
          class="ml-3"
          :items="[
            {
              text: 'All Schools',
              disabled: false,
              href: '/admin/schools/all'
            },
            {
              text: 'All Students',
              disabled: true
            }
          ]"
        />
        <v-btn
          :to="{
            name: 'admin.schools.students.add',
            params: { id: $route.params.id },
            query: { name: $route.query.name }
          }"
          color="primary"
          class="ml-10"
        >
          <span class="font font-weight-medium font-sm">Add New Student</span>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" xl="12"> </v-col>
      <v-col cols="12" sm="12" lg="12" md="12" xl="12" class="mt-n4">
        <v-data-table
          :headers="headers"
          :items="allSchools"
          :loading="pageLoading"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font box-shadow-light font-weight-medium text-capitalize mx-10"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50]
          }"
        >
          <template #item.idNumber="{item}">
            <v-chip label small color="primary">{{ item.idNumber }} </v-chip>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="{
                    name: 'admin.schools.students.add',
                    query: {
                      id: item._id,
                      action: 'edit',
                      name: $route.query.name ? $route.query.name : schoolName
                    }
                  }"
                  v-on="on"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">edit</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Edit Student Details</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :loading="isLoading"
                  @click="
                    $store.dispatch('schools/deleteStudent', {
                      id: item._id,
                      schoolId: $route.params.id
                    })
                  "
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">delete</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Delete Student Details</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
//import { debounce } from "lodash";
import { loadComponent } from "@/utils/helpers";
import { mapGetters } from "vuex";
import SnackBarMixins from "../../../../mixins/SnackBarMIxins";
export default {
  name: "AllStaffs",
  data() {
    return {
      options: {},
      search: "",
      searchTerm: null,
      schoolName: ""
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
    //PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`)
  },

  computed: {
    ...mapGetters({
      allSchools: "schools/getAllStudents",
      pageLoading: "getIsPageLoading",
      isLoading: "getIsLoading",
      paginate: "schools/getPagination"
    }),
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber
      };
    },
    staffList() {
      return this.allStaff.filter(searchTerm => {
        return (
          searchTerm.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },

    headers() {
      return [
        {
          text: "",
          value: "avatar",
          filterable: false,
          sortable: false
        },
        {
          text: "ID Number",
          value: "idNumber",
          filterable: true,
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Actions",
          value: "actions"
        }
      ];
    }
  },
  filters: {
    status(value) {
      return !value ? "Active" : "Inactive";
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },
  mixins: [SnackBarMixins],
  watch: {
    options: {
      handler(value) {
        const { itemsPerPage, page } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch("schools/listAllStudents", {
          id: this.$route.params.id,
          query
        });
      },
      deep: true
    }
  },
  methods: {},
  created() {
    this.schoolName = this.$route.query.name ?? "";
    const query = `?page=1&size=30`;
    this.$store.dispatch("schools/listAllStudents", {
      id: this.$route.params.id,
      query
    });
  }
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
