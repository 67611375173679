var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row mx-10"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"},[_vm._v(" "+_vm._s(_vm.schoolName ? _vm.schoolName : "All Students")+" ")])])]),_c('Breadcrumbs',{staticClass:"ml-3",attrs:{"items":[
          {
            text: 'All Schools',
            disabled: false,
            href: '/admin/schools/all'
          },
          {
            text: 'All Students',
            disabled: true
          }
        ]}}),_c('v-btn',{staticClass:"ml-10",attrs:{"to":{
          name: 'admin.schools.students.add',
          params: { id: _vm.$route.params.id },
          query: { name: _vm.$route.query.name }
        },"color":"primary"}},[_c('span',{staticClass:"font font-weight-medium font-sm"},[_vm._v("Add New Student")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xl":"12"}}),_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize mx-10",attrs:{"headers":_vm.headers,"items":_vm.allSchools,"loading":_vm.pageLoading,"server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [30, 40, 50]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.idNumber",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(item.idNumber)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                  name: 'admin.schools.students.add',
                  query: {
                    id: item._id,
                    action: 'edit',
                    name: _vm.$route.query.name ? _vm.$route.query.name : _vm.schoolName
                  }
                },"icon":"","fab":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Edit Student Details")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.isLoading,"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.$store.dispatch('schools/deleteStudent', {
                    id: item._id,
                    schoolId: _vm.$route.params.id
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Delete Student Details")])])]}}])})],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }